import tacho_download from './tacho_download'

class tacho_download_vehicles extends tacho_download
{
  constructor ($table) {
    super($table)
    this._$emptySearchTemplate = $('table#template_container tr.no_vehicles')
    this._$emptyFileListTemplate = $('table#template_container tr.no_files')
    this._$mainRowTemplate = $('table#template_container tr.vehicle_row')
    this._$subRowTemplate = $('table#template_container tr.file_row')

    this._searchUrl = app.CACHE.URL_AJAX + 'tacho_download_vehicles/'
    this._fileUrl = app.CACHE.URL_AJAX + 'tacho_download_vehicle_files/'

    this._perPage = 25
  }

  // attachHandlers () {
  //   super.attachHandlers()
  //
  //
  // }
}

$(() => {
  new tacho_download_vehicles($('div.data_table'))
})
